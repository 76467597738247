@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

@font-face {
    font-family: "Pixel";
    /*Can be any text*/
    src: local("Uni0553"),
        url("../fonts/pixel/Uni0553.woff") format("woff");
}

:root {
    --primary-color: #0D0D0D;
    --secondary-color: #41C385;
    --white: #fff;
    --metis: #313042;
}