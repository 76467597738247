@media only screen and (max-width: 1440px) {
    .container {
        width: 98%;
    }
}

/* 40em == 640px */
@media only screen and (max-width: 40em) {

    h1 {
        font-size: 2.29em;
    }

    h2 {
        font-size: 2.69em;
    }

    h3 {
        font-size: 1.69em;
    }

    h4 {
        font-size: 1.2em;
    }

    nav .socials {
        display: none;
    }

    nav>div {
        display: block;
        padding: 15px 0;
        width: 100%;
        justify-content: space-between;
    }

    .logo {
        margin: 0 25px;
        max-width: 220px;
        height: auto;
    }

    nav span {
        padding-right: 40px;
    }

    nav .nav-items {
        clear: both;
        max-height: 0;
        transition: max-height .2s ease-out;
        overflow: hidden;
    }

    nav .nav-items span,
    nav .nav-items a {
        display: block;
        padding: 20px 20px;
        text-align: center;
    }

    /* menu icon */

    nav .nav-icon {
        cursor: pointer;
        display: inline-block;
        float: right;
        padding: 28px 20px;

        position: relative;
        user-select: none;
    }

    nav .nav-icon .navicon {
        background: var(--white);
        display: block;
        height: 2px;
        position: relative;
        transition: background .2s ease-out;
        width: 0px;
    }

    nav .nav-icon .navicon:before,
    nav .nav-icon .navicon:after {
        background: var(--white);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        transition: all .2s ease-out;
        width: 100%;
    }

    nav .nav-icon .navicon:before {
        top: 5px;
    }

    nav .nav-icon .navicon:after {
        top: -5px;
    }

    /* menu btn */

    nav .nav-btn {
        display: none;
    }

    nav .nav-btn:checked~.nav-items {
        max-height: 240px;
    }

    nav .nav-btn:checked~.nav-icon .navicon {
        background: transparent;
    }

    nav .nav-btn:checked~.nav-icon .navicon:before {
        transform: rotate(-45deg);
    }

    nav .nav-btn:checked~.nav-icon .navicon:after {
        transform: rotate(45deg);
    }

    nav .nav-btn:checked~.nav-icon:not(.steps) .navicon:before,
    nav .nav-btn:checked~.nav-icon:not(.steps) .navicon:after {
        top: 0;
    }

    .content-wrapper a {
        font-size: 0.96em;
    }

    .intro .partners {
        padding-top: 25px;

    }

    .intro .partners img {
        transform: scale(0.7);
        margin: 0;
        width: auto !important;

    }



    .intro .content-wrapper {
        width: 100%;
        padding: 45px 0 0 0;
    }

    .intro .content-wrapper img {
        width: 65%;
    }

    .intro .content-wrapper h3 {
        margin: 20px 0 20px 0;
    }

    .intro .content-wrapper h5 {
        font-size: 0.8em;
        line-height: 1.6em;
    }

    .intro .image-wrapper {
        top: -20px;
    }

    .intro .image-wrapper img {
        width: 80%;
    }

    .intro .image-box {
        height: 95px;
        bottom: -75px;
    }

    .intro .image-box span {
        font-size: 2em;
    }

    .apply .content-wrapper {
        flex-direction: column;
    }

    .apply .content-wrapper>div,
    .apply .content-wrapper .content {
        width: 100%;
        padding: 45px 0;
    }

    .apply .content-wrapper .content h4 {
        padding: 15px;
    }

    .apply .button-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .apply .button-wrapper a {
        margin: 10px 0;
    }

    .portfolio {
        padding: 50px 0;
        overflow: hidden;
    }

    .portfolio .content-wrapper {
        width: 96%;
    }

    .thesis {
        background-color: rgba(256, 256, 256, 0.075);
        padding: 50px 0;
        overflow: hidden;
    }

    .thesis .door {
        display: none;
    }

    .thesis .content-wrapper {
        width: 90%;
    }

    .thesis .content-wrapper p {
        width: 100%;
    }

    .thesis .content-wrapper p,
    .thesis .content-wrapper h2 {
        padding: 0 10px;
    }

    .thesis .content-wrapper h2 {
        font-size: 2.21em;
    }


    .usp .content-wrapper {
        padding: 25px 0;
        flex-direction: column;
    }

    .usp.timeline .content-wrapper {
        flex-direction: row;
    }

    .usp .content-wrapper .title {
        padding: 0 0 50px 0;
        font-size: 0.8em;
        justify-content: center;
    }

    .timeline h2 {
        margin: 50px 0;
    }

    .usp.timeline .content-wrapper .usp-wrapper .content {
        padding-left: 15px;
    }

    .usp.timeline .content-wrapper .usp-wrapper h4 {
        font-size: 1.2em;
    }

    .usp .content-wrapper .usp-wrapper {
        padding: 0 10px;
    }

    .usp .content-wrapper .usp-wrapper.usp-secondary {
        top: 0;
    }

    .about .content-wrapper {
        margin: 80px 15px 120px 15px;
        padding: 50px 25px 360px 25px;
    }

    .about .content-wrapper p {
        font-size: 1em;
    }

    .roadmap .team {
        padding: 90px 0 160px 0;
    }

    .roadmap .c-left,
    .roadmap .c-right {
        width: 90%;
        transform: translateX(0%);
    }

    .roadmap .r-header {
        width: 100%;
    }

    .roadmap .r-header img {
        display: none;
    }

    .roadmap .c-right p {
        text-align: left;
    }

    .roadmap .content-wrapper .r-header h4 {
        width: 100%;
        text-align: center;
        padding: 15px 35px 22px 35px;
    }

    .teamcard-wrapper {
        justify-content: space-around;
    }

    .teamcard-wrapper .team-card {
        width: 80%;
    }

    .react-tabs__tab-panel .project,
    .portfolio .head {
        flex-direction: column;
    }

    .portfolio h2 {
        font-size: 2.29em;
        text-align: center;
    }

    .portfolio .head .tabs {
        margin: 0;
        padding: 25px 0;
    }

    .react-tabs__tab {
        margin: 12px 4px;
        font-size: 0.8em;
    }

    .react-tabs__tab-panel .project .image-wrapper {
        width: 100%;
    }

    .react-tabs__tab-panel .project .content-wrapper {
        width: 80%;
    }

    .cta {
        padding: 25px 0;
    }

    .cta .ctabg {
        display: none;
    }

    .cta .content-wrapper h4 {
        font-size: 1.2em;
    }

    footer {
        position: relative;
        z-index: 98;
        width: 100%;
        bottom: 0;

        flex-direction: column-reverse;
        align-items: flex-start;
        justify-content: center;

        background: var(--primary-color);
        padding: 45px 0px;
        border-top: 4px solid var(--secondary-color);
    }

    footer .links {
        padding: 0 15px;
    }

    footer img.logo {
        display: none;
    }

    footer strong {
        margin: 0;
    }
}

@media (min-width: 40.1em) {
    nav {
        height: 110px;
    }

    nav .nav-icon,
    nav .nav-btn {
        display: none;
    }

    .socials a:hover,
    nav span:hover {
        cursor: pointer;
        transform: translateY(-3px);
        opacity: 0.8;
    }
}

@media only screen and (max-width: 30em) {
    h1 {
        font-size: 1.8em;
    }

    h3 {
        font-size: 1.39em;
    }
}