body {
  font-family: 'MontSerrat';
  background: #000;
}


h1 {
  font-size: 2.69em;
}

h2 {
  font-size: 3.69em;
  text-align: center;
}

h3 {
  font-size: 1.6em;
  line-height: 1.6em;
}

h4 {
  font-size: 1.1em;
  font-weight: 300;
}

h5 {
  font-size: 1.1em;
  text-transform: uppercase;
  color: var(--secondary-color);
  font-weight: 300;
}

nav {
  position: fixed;
  z-index: 99;
  width: 100%;
  background: var(--primary-color);

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

nav>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

nav .outline,
nav span {
  font-family: 'MontSerrat';
  color: #848895;
  padding-right: 60px;
  transition: all .2s ease-in;
  font-size: 1.1em;
  cursor: pointer;
}

nav .socials {
  margin-right: 35px;
}



.logo {
  height: 77px;
  margin: 0 60px;
  transition: transform .3s ease-in-out;
}

.logo:hover {
  transform: rotate(0.4deg);
  cursor: pointer;
}

.container {
  width: 1440px;
  margin: 0 auto;
}

.intro {
  overflow: hidden;
}

.intro .container {
  display: flex;
  flex-wrap: wrap;
  text-align: center;

  padding-top: 110px;
}

.intro .content-wrapper {
  display: flex;
  flex-direction: column;

  width: 60%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 120px 0 0 0;
}

.intro .content-wrapper h1 {
  margin-bottom: 45px;
  font-weight: 600;
}

.intro .content-wrapper h3 {
  margin: 20px 0 20px 0;
  opacity: 0.9;
  font-weight: 600;
  width: 75%;
}

.intro .content-wrapper h5 {
  margin: 45px 0 0 0;
}

.intro .content-wrapper img {
  margin-bottom: 35px;
}

.intro .content-wrapper p {
  max-width: 75%;
  line-height: 1.4em;
  font-weight: 100;
}

.content-wrapper a {
  background: var(--white);
  font-family: 'MontSerrat';
  font-weight: 600;
  padding: 12px 25px;
  margin: 25px 0;
  font-size: 1.2em;
  transition: 0.5s;
  color: black;
  text-transform: uppercase;
}

.content-wrapper a:hover,
.content-wrapper a:focus {
  box-shadow: inset 10.5em 0 0 0 var(--primary-color);
  color: var(--white);
  cursor: pointer;
}

.timeline h2 {
  margin: 120px 0 0 0;
}

.usp.timeline .content-wrapper .usp-wrapper {
  width: 50%;
}

.usp.timeline .content-wrapper .usp-wrapper h4 {
  font-size: 1.9em;
  font-weight: 600;
  border: 0;
  padding: 0;
}

.usp.timeline .content-wrapper .usp-wrapper .content {
  border-left: 1px solid var(--white);
  padding-left: 25px;
}

.usp.timeline .content-wrapper .usp-wrapper.usp-secondary .content {
  border-left: none;
  border-right: 1px solid var(--white);
  padding-left: 0;
  padding-right: 25px;
}

.usp.timeline .content-wrapper .usp-wrapper.usp-secondary h4,
.usp.timeline .content-wrapper .usp-wrapper.usp-secondary p {
  text-align: right;
}

.portfolio {
  padding: 160px 0 120px 0;
  display: flex;
  align-items: center;
}

.portfolio>div {
  display: flex;
  justify-content: center;
}

.portfolio .content-wrapper {
  width: 90%;

}

.portfolio h2 {
  font-size: 2.69em;
  text-align: left;
}

.portfolio .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 15px;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 12px 12px;
  margin: 0 7px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  border: 1px solid #22EF8D;
  background: #111111;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  border: 1px solid #22EF8D;
  outline: none;
}

.react-tabs__tab-panel {
  display: none;

  margin: 30px 0;
  background: #111111;
}

.react-tabs__tab-panel .project {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.react-tabs__tab-panel .project .image-wrapper {
  width: 50%;
}

.react-tabs__tab-panel .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.react-tabs__tab-panel .project .content-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
}

.react-tabs__tab-panel .project .content-wrapper img {
  max-height: 100px;
  margin: 0 0 20px 0;

}

.react-tabs__tab-panel h4 {
  font-weight: 500;
  font-size: 1.6em;
}

.react-tabs__tab-panel h6 {
  font-size: 1.2em;
  margin: 6px 0 0 0;
}

.react-tabs__tab-panel p {
  line-height: 1.6em;
  font-size: 0.9em;
  font-weight: 100;
}

.react-tabs__tab-panel .project .content-wrapper a {
  display: block;
  background: transparent;
  color: var(--secondary-color);
  padding: 0;
  margin: 15px 0;
  text-transform: none;
  font-weight: 100;
}

.react-tabs__tab-panel .project .content-wrapper a:hover {
  opacity: 0.8;
}

.react-tabs__tab-panel--selected {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.usp .content-wrapper {
  display: flex;
  justify-content: center;
  padding: 180px 0;
}

.usp .content-wrapper h3 {
  font-size: 2.1em;
  line-height: 1.2em;
  font-weight: 100;
}

.usp .content-wrapper .title {
  min-width: 20%;
  padding-right: 75px;
  display: flex;
  justify-content: end;
}

.usp .content-wrapper .usp-wrapper {
  min-width: 20%;
  padding: 0 75px;
}

.usp .content-wrapper .usp-wrapper.usp-secondary {
  position: relative;
  top: -45px;
}

.usp .content-wrapper .usp-wrapper .content {
  margin-bottom: 75px;
}

.usp .content-wrapper .usp-wrapper h4 {
  font-size: 1.5em;
  border-left: 2px solid #22EF8D;
  padding: 3px 0 3px 12px;
}

.usp .content-wrapper .usp-wrapper p {
  opacity: 0.4;
  font-weight: 100;
  line-height: 1.4em;
}

.usp .content-wrapper .usp-wrapper h5 {
  font-size: 1.7em;
  color: var(--white);
  font-weight: 500;
}

.usp .content-wrapper .usp-wrapper h6 {
  text-transform: uppercase;
  font-weight: 100;
  font-size: 1em;
  opacity: 0.8;
}

.partners {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.4;
  overflow: hidden;
  width: 300%;
}

.partners .image-wrapper img {
  max-height: 160px;
  margin: 0 30px;
  filter: brightness(0) invert(1);
}

.partners .image-wrapper img.near {
  max-height: 110px;
}

.partners .image-wrapper img.immutable {
  height: 60px;
}

.partners .image-wrapper img.solstark {
  max-height: 40px;
}

#chains {
  margin-left: 25px;
}

#partner {
  margin-top: -50px;
  margin-left: -70px;
}

.apply {
  padding-top: 120px;
}

.apply .content-wrapper {
  display: flex;
  width: 100%;
}

.apply .content-wrapper>div {
  width: 50%;
}

.apply .content-wrapper .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 460px;
  padding: 120px 160px 0 160px;
}

.apply .button-wrapper {
  margin: 0 0 120px 0;
  display: flex;
  justify-content: center;
}

.apply .content-wrapper img {
  height: 80px;
  margin-bottom: 45px;
}

.apply .content-wrapper h4 {
  text-align: center;
  line-height: 1.4em;
  display: inline-block;
}

.apply .content-wrapper a {
  margin: 45px 15px;
  border: 2px solid var(--white);
}

.apply .content-wrapper .vizr a:hover,
.apply .content-wrapper .vizr a:focus {
  box-shadow: inset 10.5em 0 0 0 #22EF8D;
}

.apply .button-wrapper a.outline {
  background-color: transparent;
  border: 2px solid var(--white);
  color: var(--white);
}

.apply .content-wrapper .outline:hover,
.apply .content-wrapper .outline:focus {
  box-shadow: inset 10.5em 0 0 0 var(--white);
  color: var(--primary-color);
}

.apply .content-wrapper .vizr {
  background-color: var(--secondary-color);

}

.thesis {
  position: relative;
  padding: 160px 0 40px 0;
}

.thesis .door {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0px;
  z-index: -1;
}

.thesis .door img {
  position: absolute;
  right: 0;
  width: 100%;
}

.thesis .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 82%;

  margin: 60px 0 0 0;
}

.thesis .content-wrapper h2 {
  color: #fff;
  text-align: left;
  font-size: 2.9em;
  margin-bottom: 25px;
}

.thesis .content-wrapper h4 {
  font-weight: 400;
  font-size: 2em;
  padding: 25px 0;
}

.thesis .content-wrapper p {
  width: 80%;
  font-size: 1em;
  font-weight: 300;
  line-height: 2em;
}

.cta {
  position: relative;
  background-color: var(--secondary-color);
  padding: 160px 0;
  color: var(--white);
}

.cta .ctabg {
  position: absolute;
  width: 100%;
  top: 50%;
  right: 0px;
  z-index: 1;
}

.cta .ctabg img {
  position: absolute;
  right: 0;
  transform: translateY(-50%);
}

.cta .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1080px;
  margin: 0 auto;
}

.cta .content-wrapper h4 {
  font-size: 1.9em;
  line-height: 1.6em;
  text-align: center;
  margin-bottom: 25px;
}

.cta .content-wrapper a:hover,
.cta .content-wrapper a:focus {
  box-shadow: inset 12.5em 0 0 0 #22EF8D;
}

footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 55px 45px;
}

footer .logo {
  margin: 0;
}

footer .socials {
  margin: 15px 0 25px 12px;
}

footer .socials a {
  margin-right: 10px;
}

footer .info strong {
  margin-left: 12px;
  font-weight: 100;
  color: #848895;
}

footer .links {
  display: flex;
  flex-direction: column;
}

footer .links strong {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 100;
}

footer .links a {
  margin: 9px 0;
  color: #848895;
  font-weight: 100;
  transition: transform .3s ease-in-out;
}

footer .links a:hover {
  filter: brightness(0) invert(0.8);
}

.height {
  min-height: 100vh;
}